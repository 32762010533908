.chart-title {
    color: #05668d;
    font-size: 32px;
    font-weight: 700;
}

.row {
    display: flex;
}


.button-container {
    margin-left: auto;
}

.popover-body {
    position: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
}