.status {
    display: inline-block;
    padding: 4px 3% 4px;
    border-radius: 4px;
    min-width: 80px;
}

.status-pending {
    background-color: #FFA737;
    color: #F9F9F9;
}

.status-accepted {
    background-color: #02C39A;
    color: #F9F9F9;
}

.status-declined {
    background-color: #D34B36;
    color: #F9F9F9;
}

.type {
    color: #05668D
}

.amount {
    width: 40%;
}


.harvest-table.disabled {
    pointer-events: none; /* Disable interactions */
    opacity: 0.5; 
}

.expanded-content {
    background-color: #ffffff;
    padding: 1rem;
    width: 65%;
    border-style: solid;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
}

.expanded-content>div:not(.button-stack) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 0.8rem;
}


.expanded-content div span:first-child {
    width: 15%;
    text-align: left;
    white-space: nowrap;
}


.expanded-content div span:last-child {
    width: 70%;
    text-align: left;
    word-wrap: break-word;
    max-width: 90%;
}

.comment-section {
    max-width: 100%;
}

.expanded-row {
    display: flex;
    justify-content: center;
    background-color: #ffffff
}


.expanded-row:hover {
    background-color: #ffffff;
}

.divider {
    border-bottom: 1px solid #B5B5B5;
    width: 92%;
    margin-left: auto;
    margin-right: auto;
}

.button-stack {
    width: 93%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 11%;
}


.request-number {
    position: absolute;
    align-self: flex-end;
    padding: 1rem 1.3rem 0 0;
}

.bold-row td {

    background-color: #FAE9D2;
}