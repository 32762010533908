
body, html {
  background-color: #ECECEC;
  
}

.App {
  width: 100%;
 
}

.content-container {
  width: calc(100% - 260px);
  height: 100%;

}

.App .MuiPaper-root {
  background: #ECECEC;
  border-right: none
}


.App .MuiList-root .Mui-selected {
  background-color: #ECECEC;
  color: #02C39A
}


.App .MuiList-root .Mui-selected .MuiSvgIcon-root {
  color: #02C39A
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.MuiListItemIcon-root {

  /* filter: invert(76%) sepia(43%) saturate(5816%) hue-rotate(122deg) brightness(90%) contrast(98%); */
}



.amplify-button[data-variation='primary'] {
  background: hsl(33.6, 100%, 60.8%)
}

.amplify {
  background: hsl(33.6, 100%, 60.8%)
}

.amplify-flex > div {
  margin-bottom: 10px; 
}

[data-amplify-authenticator] [data-amplify-router] {
  border-radius: 10px;
  border-color: transparent;
}