.custom-icon {
    width: 20%;
    height: 20%;
}

.popover_class {
    width: 100%;
}

.harvest-button {
    flex-shrink: 1;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffffb3;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure the overlay is above other elements */
}

/*.popover_class {
    margin-top: 10px;
    border: 1px solid black;
}

.popover_class::before {
    content: "";
    position: absolute;
    right: 4px;
    top: -50px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent green transparent;
    z-index: 9998;
}*/