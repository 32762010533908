.date {
    width: 45.5%;
    font-weight: bold;
    border: 0;
    color: #110202;
}

.date input {
    color: #110202;
    font-weight: 500;
    border-color: transparent;
    font-size: 14px;
}

.date .MuiSvgIcon-root {
    color: #110202;

}

.myDatePicker .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

.myDatePicker .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

.myDatePicker .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}