.chart-container {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    /* TODO: a suggestion for scroll to be only local to the chart and not the whole page*/
    /*
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 5px;
    */
}

.axis-label {
    font-size: 30px;
    font-weight: bold;
}

.y-axis-label {
    transform: rotate(-90deg);
    text-anchor: middle;
    font-size: 14px;
    font-weight: bold;
}

.custom-reference-line {
    z-index: 9999;
}

.vertical-reference-line {
    z-index: 1;
}

.dot {
    font-size: 50px;
}

.negative-value {
    fill: red;
}

.custom-label-text {
    font-size: 14px;
}