.thumbnail-gallery {
  padding: 0px;
}

.date-section {
  margin-bottom: 24px;
  font-size: 14px;
  text-align: center;
  color: #05668D;
}

.date-section h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  position: relative;
}

.date-section h4::before,
.date-section h4::after {
  content: '';
  flex: 1;
  height: 1px;
  background-color: #05668D;
  margin: 0 10px;
}
.thumbnail-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-top: 10px;
  gap: 10px;
}
.thumbnail-item {
  width: 100%;
  height: 100px; 
  height: auto;
  cursor: pointer; 
 
}
.thumbnail-item:hover {
  transform: scale(1.2);
}
.enlarged-image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; 
}
.enlarged-image {
  max-width: 100%;
  max-height: 100%;
  border: 2px solid #ccc; 
}

.button-gallery {
  display: flex;
  justify-content: flex-start; 
}

.end-message {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
.unselected-note-icon {
  width: 16px; 
  height: 16px;
  margin-right: 5px; 
  vertical-align: middle; 
}
 /*styling for Note*/
.thumbnail-gallery {
  display: flex;
  flex-direction: column;
}

.calendar-week {
  text-align: center;
  margin-top: -20px ;
}

.calendar-week h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #05668D;
}

.calendar-week h4::before,
.calendar-week h4::after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background-color: #05668D; 
  margin: 0 10px;
}

.no-data-note {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center; 
  text-align: center;
}

.no-data-note p {
  margin: 150px;
  font-size: 25px;
  color: #110202;
}

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffb3;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.thumbnail-label {
  display: flex;
  align-items: flex-start; 
  justify-content: center;
  align-items: center; 
}

.unselected-note-icon {
  margin-right: 10px; 
}

.thumbnail-label span {
  display: flex;
  align-items: center;
  flex-direction: column; 
}
