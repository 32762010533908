
.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 
}

.card-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.controls-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
  }
  
  .enlarged-image-container {
    position: relative;
    display: flex;
    min-width: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .toggle-thumbnails-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: auto;
  }
.card-title {
    font-size: 24px;
    font-weight: bold;
}

.button-container {
    display: flex;
    justify-content: flex-end;
}