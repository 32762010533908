.cardCheckBox {
    margin: 1rem;
    background-color: #ffffff;
    padding: 1rem;
    width: auto;
    min-width: 95%;
    height: auto;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}


.cardCheckBox_header {

    color: #05668D;
    font-size: xx-large;
    text-align: left;
    font-weight: 900;

    padding-top: 0.8rem;

}

.cardCheckBox_body {
    padding-top: 1rem;
    padding-left: 0.5rem;

}