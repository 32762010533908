.logoContainer {
    display: flex;
    align-items: center;
    margin-right: 92px;
}


.logoContainer img {
    height: 32px;
}

.searchContainer {
    position: relative;
    margin-right: 100px;
    margin-left: 0px;
    width: 100%;
    border: 1px solid white;
    /* Add this line */
    border-radius: 10px;
    /* Add this line */
}

.searchContainer input {
    color: inherit;
    padding: 8px 8px 8px 40px;
    transition: width 300ms ease-in-out;
    width: 100%;
    border: none;
    /* Add this line */
    border-radius: 10px;
    /* Add this line */
    background-color: white;
    /* Add this line */
}

.searchIconWrapper {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
}

.notificationsButton {
    margin-right: 16px;

}

.accountButton {
    margin-right: 8px;

}


.menuIcon {
    margin-right: 32px;
}

.harvestLogo {
    margin-right: 32px;
}

.MuiPopover-paper {
    padding: 8px;
}

.sign-out button {
    background-color: transparent;
    color: #ffa737;
    border: 2px solid rgb(255, 167, 55);
    cursor: pointer;
    font-size: 16px;
    border-radius: 3px;
}

.sign-out button:hover {
    background-color: #ffa737;
    color: #ffffff;
    border-radius: 3px;
}

.initials-button {
    background-color: #ffffff;
    color: #000000;
    border: 2px solid #000000;
    cursor: pointer;
    font-size: 20px;
    border-radius: 8px;
    padding: 1px 6px;
    text-decoration: none;
    display: inline-block;
    font-weight: 900;
}

.custom-popover {
    width: 300px;
    height: 400px;
}

.toolTip {
    background-color: #ffffff;
}