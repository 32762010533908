.crop-selector-container.horizontal {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.crop-selector-container.vertical {
  display: flex;
  flex-direction: column;
}

.crop-selector-container.vertical .dropdown-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.dropdown-container {

  margin-bottom: 10px;
  column-gap: 26px;
}

.crop-selector-container.vertical .dropdown-container label {
  width: 150px;
  margin-right: 40px;
}

.with-note {
  background: none;
  color: grey;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  outline: none;
}

.with-note:hover {
  color: orange;
}

.with-note.active img,
.with-note.active span {
  color: #FFA500;
}