/* Title Styling */
.info-title {
  font-weight:600 !important;
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 10px;
    color: black;
  }
  .info-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    row-gap: 6px;
    padding-bottom: 10px;
  }
  
  /* Labels Styling */
  .info-label {
    font-weight:600 !important;
    font-size: 14px !important;
    color: #000;
  }
 
  /* Values Styling */
  .info-value {
    color: black;
    font-size: 14px !important;
    padding-left: 10px;
  }
  
  
  .info-divider {
    height: 1px;
    background-color: black;
    margin: 16px ;
  }
  