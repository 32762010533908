.popup-div {
    position: fixed;
    top: 50%;
    left: 55%;
    width: 40%;
    min-height: 50%;
    max-height: 80%; 
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: 1px 1px 4px 4px #bebbbb;
    border-radius: 10px;
}