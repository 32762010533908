table {
    width: 100%;
    border-collapse: collapse;
}

th {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 16px;
    color: #717171;
    border-bottom: 2px solid #ccc;
    padding: 8px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.016em;
    border-bottom: 1px solid #ccc;
}

td {
    background-color: #ffffff;
    padding: 8px;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    color: #110202;
}

tr:hover {
    background-color: #f5f5f5;
}


.oval-th {
    width: 10px;
    padding: 0px;
}


.oval-td {
    width: 10px;
    padding: 0px;
}


.cardCheckBox table {
    text-align: center;
}

.oval-last ellipse {
    fill: #69A3BB;
}

.oval-penultimate ellipse {
    fill: #05668D
}

.scheduled-last {
    color: #05668D;
}

.scheduled-harvest {
    color: #05668D;
}