.report-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 20px;
    height: 100%;
    width: 100%;
}

.chart-container {
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.table-container {
    grid-area: 2 / 1 / 3 / 2;
}

.add-harvest-events-container {
    position: absolute;
    top: 8;

}

.indoor-climate-container {
    width: 100%;

}

.no-data-container {
    text-align: center;
    font-size: 45px;
    font-weight: bold;
    padding: 100px;
    margin: 20px;
}

.no-data-title {
    font-weight: 500;
    color: #2d2d2d;
    font-size: 36px;
    margin-bottom: 20px;
}

.no-data-message {
    font-weight: 400;
    color: #6c757d;
    font-size: 20px;
    line-height: 1.6;
    max-width: 600px;
    margin: 0 auto;
}

.no-data-link {
    color: #007BFF;
    text-decoration: none;
    font-weight: 500;
}

.no-data-link:hover {
    text-decoration: underline;
}