.popup-container {
    position: fixed;
    top: 50%;
    left: 55%;
    width: 500px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: 1px 1px 4px 4px #bebbbb;
    border-radius: 10px;
 
}

.popup-header {
    background-color: #B4D1DD;
    border-radius: 6px 6px 0px 0px;
    padding: 20px;

}

.dropdown-wrapper {
    display: flex;
    align-items: center;
}

.dropdown-label {
    font-weight: bold;
    margin-right: 7rem; /* desirable gap */
}


.bold-label {
    font-weight: bolder;
}

.popup-header h6 {
    color: black;
    margin: 0;
    font-weight: bold;
    font-size: 16px;
}

.popover-body {
    display: flex;
    flex-direction: column;
    margin: 0 2% 2% 2%;
}

.popup-buttons {
    display: flex;
    justify-content: space-between;
    margin: 10 0 10 0;

}

